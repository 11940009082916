import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UniversalApiCallsService } from 'src/app/service/universal/universal-api-calls.service';

export interface Application {
  name: string;
  displayName: string;
  oudAppName?: string;
  isRegistered?: boolean;
  isTradeMarked?: boolean;
  order?: number;
  homeURL?: string;
  learnMoreURL?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  appLoginJson?: AppLoginJson;
  linkText?: string;
  description?: string;
  src?: string;
}

export interface AppLoginJson {
  transmitJourney?: string;
  samlActionUrl?: string;
  relayState?: string;
  logoutUrl?: string;
  mobileLogoutUrl?: string;
}

export interface SamlJson {
  samlActionUrl?: string;
  relayState?: string;
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  public gateway = environment.univ_treasuryLogin;
  appInfo = new Map();
  apps = new Map();
  mappingLookup = new Map();
  isCurrentRoute = false;

  // config
  configApplications: Application[];
  configApps: any;
  nonOUDAppsList: any;
  oudAppsList: any;
  shortNameMap: { [displayName: string]: string } = {};

  constructor(
    private readonly universalApiCallsService: UniversalApiCallsService
  ) { }

  init() {
    this.universalApiCallsService.getAppsData().subscribe(data => {
      this.configApps = data;
      this.nonOUDAppsList = data.filter(app => !app.isOUDApp).map(data => data.applicationName);
      this.oudAppsList = data.filter(app => app.isOUDApp).map(data => data.applicationName);
      data.forEach(val => {
        this.apps.set(val.name, val);
        this.apps.forEach((item) => {
          this.mappingLookup.set(item.mappingName, item);
        })
        this.shortNameMap[val.displayName] = val.applicationName
      });
    })
  }

  initDashboard() {
    const isSMBUser = sessionStorage.getItem("isSMBUser") === "true";
    if (isSMBUser) {
      this.gateway = environment.univ_smbUserLogin;
    }

    if (this.gateway !== undefined) {
      const apps: Application[] = this.gateway;
      this.setGatewayData(apps);
    }
  }

  getNonOUDAppsList() {
    return this.nonOUDAppsList;
  }

  getOUDAppsList() {
    return this.oudAppsList;
  }

  getConfigAppsData(selectedAppName: any) {
    return this.configApps.find((val: any) => val.name === selectedAppName);
  }

  getShortAppName(selectedAppName: any) {
    return this.shortNameMap[selectedAppName] || '';
  }

  isApp(appName: string) {
    return Array.from(this.apps.values()).some(app => app.mappingName === appName);
  }

  getHomeUrl(appName: string): string {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.homeURL;
    }
    return undefined;
  }

  getLearnMoreUrl(appName: string): string {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.learnMoreURL;
    }
    return undefined;
  }

  getGatewayData(): object {
    return this.appInfo;
  }

  setGatewayData(apps: Application[]) {
    apps.forEach(data => {
      this.appInfo.set(data.name, {
        name: data.name,
        displayName: data.displayName,
        oudAppName: data.oudAppName,
        isRegistered: data.isRegistered,
        order: data.order,
        homeURL: data.homeURL,
        learnMoreURL: data.learnMoreURL,
        isDisabled: data.isDisabled,
        linkText: data.linkText,
        description: data.description,
        imgSrc: data.src
      });
    });
  }

  getTransmitJourney(appName: string): string {
    return this.mappingLookup.get(appName)?.appLoginJson?.transmitJourney || undefined;
  }

  getDisplayName(appName: string): string {
    return this.mappingLookup.get(appName)?.displayName || undefined;
  }

  getApplicationName(appName: any) {
    return this.mappingLookup.get(appName).applicationName;
  }

  getNonOUDApps(appName: string): boolean {
    this.universalApiCallsService.getAppsData().subscribe(data => {
      data.forEach(val => {
        if (val.name === appName)
          return true;
      })
    })
    return false;
  }

  getOudAppName(appName: string): string {
    return this.mappingLookup.get(appName)?.oudAppName || undefined;
  }

  getIsRegistered(appName: string): boolean {
    return this.mappingLookup.get(appName)?.isRegistered;
  }

  getIsTradeMarked(appName: string): boolean {
    return this.mappingLookup.get(appName)?.isTradeMarked;
  }

  getSamlInfo(appName: string): object {
    const app = this.mappingLookup.get(appName)
    return app ? { samlActionUrl: app.appLoginJson?.samlActionUrl, relayState: app.appLoginJson?.relayState } : undefined;
  }

  getLogoutInfo(appName: string): object {
    const app = this.mappingLookup.get(appName)
    return app ? { logoutUrl: app.appLoginJson?.logoutUrl, mobileLogoutUrl: app.appLoginJson?.mobileLogoutUrl } : undefined;
  }

  getAppInfo(appName: string): object {
    const app = this.mappingLookup.get(appName)
    return app ? {
      displayName: app?.displayName,
      oudAppName: app?.oudAppName,
      isRegistered: app?.isRegistered,
      isActive: app?.isActive
    } : undefined
  }

  textEllipis(value) {
    if (value.length > 15) {
      return value.substring(0, 12) + '...';
    }
    return value;
  }
}